import { graphql, PageProps, Link } from 'gatsby'
import React from 'react'
import { DUMMY_TOKEN } from 'hooks/use-signup-object'
import * as paths from '../lib/paths'

import '../components/variables.css'
import '../components/global.css'
import * as styles from './directory.module.css'

type ProgramPartnerPageNode = {
  domain: string
  partnerSlug: string
  programSlug: string
}

type SlugPageNode = {
  domain: string
  slug: string
}

type DataProps = {
  allContentfulPageLanding: {
    nodes: ProgramPartnerPageNode[]
  }
  allContentfulPageForm: {
    nodes: ProgramPartnerPageNode[]
  }
  allContentfulPageConfirmation: {
    nodes: ProgramPartnerPageNode[]
  }
  allContentfulPageDirectory: {
    nodes: SlugPageNode[]
  }
  allContentfulPageFaq: {
    nodes: SlugPageNode[]
  }
}

type DomainDirectory = {
  [key: string]: {
    partnerDirectory: PartnerDirectory
    slugDirectory: SlugDirectory
  }
}

type PageDirectory = {
  [key: string]: boolean
}

type ProgramDirectory = {
  [key: string]: PageDirectory
}

type PartnerDirectory = {
  [key: string]: ProgramDirectory
}

type SlugDirectory = {
  [key: string]: PageDirectory
}

const addProgramPartnerDirectoryPages = (
  directory: DomainDirectory,
  pages: ProgramPartnerPageNode[],
  index: string
) => {
  for (const page of pages) {
    const domain = page.domain || 'Thermostat'

    if (page.partnerSlug && page.programSlug) {
      directory[domain] ||= {
        partnerDirectory: {},
        slugDirectory: {},
      }
      directory[domain].partnerDirectory[page.partnerSlug] ||= {}
      directory[domain].partnerDirectory[page.partnerSlug][page.programSlug] ||=
        {}
      directory[domain].partnerDirectory[page.partnerSlug][page.programSlug][
        index
      ] = true
    }
  }
}

const addSlugDirectoryPages = (
  directory: DomainDirectory,
  pages: SlugPageNode[],
  index: string
) => {
  for (const page of pages) {
    const domain = page.domain || 'Thermostat'

    if (page.slug) {
      directory[domain] ||= {
        partnerDirectory: {},
        slugDirectory: {},
      }

      directory[domain].slugDirectory[page.slug] ||= {}
      directory[domain].slugDirectory[page.slug][index] = true
    }
  }
}

const makeDomainDirectory = (data: DataProps) => {
  const directory = {} as DomainDirectory
  makeProgramPartnerDirectory(directory, data)
  makeSlugDirectory(directory, data)
  return directory
}

const makeProgramPartnerDirectory = (
  directory: DomainDirectory,
  data: DataProps
) => {
  addProgramPartnerDirectoryPages(
    directory,
    data.allContentfulPageLanding.nodes,
    'landing'
  )
  addProgramPartnerDirectoryPages(
    directory,
    data.allContentfulPageForm.nodes,
    'form'
  )
  addProgramPartnerDirectoryPages(
    directory,
    data.allContentfulPageConfirmation.nodes,
    'confirmation'
  )
  return directory
}

const makeSlugDirectory = (directory: DomainDirectory, data: DataProps) => {
  addSlugDirectoryPages(
    directory,
    data.allContentfulPageDirectory.nodes,
    'directory'
  )
  addSlugDirectoryPages(directory, data.allContentfulPageFaq.nodes, 'faq')
  return directory
}

const RootPage: React.FC<PageProps<DataProps>> = (props) => {
  const domainDirectory = makeDomainDirectory(props.data)

  return (
    <div className="container">
      <h1>Directory</h1>
      {Object.entries(domainDirectory).map(([domain, directories]) => {
        return (
          <div key={domain}>
            <h2>{domain}</h2>
            <div className={styles.flex}>
              <div className={styles.column}>
                <h3>Slug Pages</h3>
                {Object.entries(directories.slugDirectory).map(
                  ([slug, pages]) => {
                    return (
                      <div key={slug}>
                        <h4>{slug}</h4>
                        <ul>
                          {pages.directory && (
                            <li>
                              <Link to={paths.directoryPath(domain, slug)}>
                                Directory
                              </Link>
                            </li>
                          )}
                          {pages.faq && (
                            <li>
                              <Link to={paths.faqPath(domain, slug)}>FAQ</Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    )
                  }
                )}
              </div>
              <div className={styles.column}>
                <h3>Program/Partner Pages</h3>
                {Object.entries(directories.partnerDirectory).map(
                  ([partner, programs]) => {
                    return (
                      <div key={partner}>
                        {Object.entries(programs).map(([program, pages]) => {
                          return (
                            <div key={program}>
                              <h4>
                                {partner}: {program}
                              </h4>
                              <ul>
                                {pages.landing && (
                                  <>
                                    <li>
                                      <Link
                                        to={paths.landingPath({
                                          domain,
                                          partner,
                                          program,
                                        })}
                                      >
                                        Landing
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={paths.nowPath({
                                          domain,
                                          partner,
                                          program,
                                        })}
                                      >
                                        Immediate Redirect
                                      </Link>
                                    </li>
                                  </>
                                )}
                                {pages.form && (
                                  <li>
                                    <Link
                                      to={paths.formPath({
                                        domain,
                                        partner,
                                        program,
                                        signUpToken: DUMMY_TOKEN,
                                      })}
                                    >
                                      Form
                                    </Link>
                                  </li>
                                )}
                                {pages.confirmation && (
                                  <li>
                                    <Link
                                      to={paths.confirmationPath({
                                        domain,
                                        partner,
                                        program,
                                        signUpToken: DUMMY_TOKEN,
                                        displayDevices: true,
                                      })}
                                    >
                                      Confirmation
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )
                        })}
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RootPage

export const pageQuery = graphql`
  query RootDirectoryQuery {
    allContentfulPageLanding {
      nodes {
        domain
        partnerSlug
        programSlug
      }
    }
    allContentfulPageForm {
      nodes {
        domain
        partnerSlug
        programSlug
      }
    }
    allContentfulPageConfirmation {
      nodes {
        domain
        partnerSlug
        programSlug
      }
    }
    allContentfulPageDirectory {
      nodes {
        domain
        slug
      }
    }
    allContentfulPageFaq {
      nodes {
        domain
        slug
      }
    }
  }
`
